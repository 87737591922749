<template>
  <b-table v-if="show" small striped hover sort-by="total" :sort-desc="true" :fields="fields" :items="revenueData" responsive>
    <template #thead-top>
      <b-tr>
        <b-th>Overall</b-th>
        <b-th v-for="(month, index) in monthList" :key="index">{{ overall[month] ? overall[month] : '0' | toDollarAmnt }}</b-th>
      </b-tr>
    </template>
    <template #cell()="data">
      <i>{{ data.value | toDollarAmnt }}</i>
    </template>
    <template #custom-foot>
      <b-tr class="text-bold">
        <b-td>Overall</b-td>
        <b-td v-for="(month, index) in monthList" :key="index">{{ overall[month] ? overall[month] : '0' | toDollarAmnt }}</b-td>
      </b-tr>
    </template>
  </b-table>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

const DT_FORMAT = 'YYYY-MM-DD'

export default {
  name: 'RevenueOverview',
  props: {
    startDate: {
      // type: String,
      default: () => this.$moment().format('YYYY-MM-DD')
    },
    numberOfMonths: {
      type: Number,
      default: 12
    },
    businessId: {
      type: Number,
      default: null
    },
    src: {
      type: String,
      default: 'reports'
    }
  },
  data () {
    return {
      show: false,
      monthList: [],
      businesses: [],
      overall: {},
      revenueData: []
    }
  },
  computed: {
    ...mapGetters([
      'invoices'
    ]),
    fields () {
      const monthList = [ ...this.monthList ]
      monthList.unshift('business')
      return monthList.map(e => ({
        key: e,
        sortable: true
      }))
    },
    reportStartMonth () {
      return this.$moment(this.startDate).startOf('month').format(DT_FORMAT)
    },
    reportEndMonth () {
      return this.$moment(this.startDate).format(DT_FORMAT)
    },
  },
  mounted () {
    this.setmonthList()
    this.doGetBusinessRevenue()
  },
  watch : {
    startDate () {
      this.setmonthList()
      this.doGetBusinessRevenue()
    },
    numberOfMonths () {
      this.setmonthList()
      this.doGetBusinessRevenue()
    },
    businessId () {
      this.setmonthList()
      this.doGetBusinessRevenue()
    },
    src () {
      this.setmonthList()
      this.doGetBusinessRevenue()
    }
  },
  methods: {
    ...mapActions([
      'getBusinessRevenue'
    ]),
    setRevenueData (revenueData) {
      const ret = []
      const def = this.monthList.reduce((p, c) => {
        p[c] = 0
        return p
      }, {})
      def.total = 0
      this.overall = { ...def }

      const revData = revenueData.reduce((prev, curr) => {
        let areaName = curr.business.areaName
        let month = `${curr.year}/` + String(curr.month).padStart(2, '0')
        if (!prev[areaName]) {
          prev[areaName] = { ...def }
        }
        let add = curr.totalRevenue ? curr.totalRevenue : 0
        prev[areaName][month] += add
        prev[areaName].total += add

        this.overall[month] += add
        this.overall.total += add
        return prev
      }, {})

      for (const k in revData) {
        let res = { ...{ business: k }, ...revData[k] }
        ret.push(res)
      }

      this.revenueData = ret
      this.show = true
    },
    setmonthList () {
      const months = []
      let i = 0
      while (i < this.numberOfMonths) {
        months.push(this.$moment(this.reportStartMonth).add(i, 'months').format('YYYY/MM'))
        i++
      }
      if (this.numberOfMonths > 1) { months.push('total') }
      this.monthList = months
    },
    async doGetBusinessRevenue () {
      const revenueData = await this.getBusinessRevenue({
        src: this.src,
        numMonths: this.numberOfMonths,
        startDate: this.$moment(this.startDate).format('YYYY-MM-DD'),
        business: this.businessId
      })
      this.setRevenueData(revenueData)
    }
  }
}
</script>

<style lang="scss">
th, .source-label {
  white-space: nowrap;
  font-weight: bold;
}
.divider {
  font-weight: bold;
  font-size: 1.2rem;
  color: royalblue;
}
</style>
