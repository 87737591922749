<template>
  <div className="animated fadeIn">
    <i class="icon-cui-graph mr-1"></i> Revenue Overview<hr />
    
    <b-row class="justify-content-center">
      <b-col cols="12" md="6" lg="4">
        <div class="d-flex flex-column mb-3">
          <b-form-select v-model="src" class="mb-2">
            <b-form-select-option :value="null" disabled>Pull By...</b-form-select-option>
            <b-form-select-option value="reports">Reports Submitted</b-form-select-option>
            <b-form-select-option value="invoices">Invoices Paid</b-form-select-option>
          </b-form-select>

          <b-button-toolbar class="mb-2" key-nav aria-label="Time Frame Options" justify>
            <b-btn size="sm" @click="decreaseStartDate()"><i class="fa fa-arrow-left"></i></b-btn>
            <vue-monthly-picker
              class="mx-auto"
              v-model="startDate"
              :max="$moment()"
              :clearOption="false"
            ></vue-monthly-picker>
            <b-btn size="sm" @click="increaseStartDate()"><i class="fa fa-arrow-right"></i></b-btn>
          </b-button-toolbar>
          
          <b-button-group size="sm">
            <b-button
              @click="months = num.months"
              v-for="num in timeFrameOptions"
              :key="num.months"
              :variant="(months === num.months ? 'info' : 'secondary')"
            >{{ num.label }}</b-button>
          </b-button-group>
        </div>
      </b-col>
    </b-row>
    <b-row class="justify-content-center">
      <b-col>
        <RevenueOverview :numberOfMonths="months" :startDate="startDate" :src="src" />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import VueMonthlyPicker from 'vue-monthly-picker'
// import RevenueOverview from '@/components/RevenueOverview'
import RevenueOverview from './~RevenueOverview.vue'

const DEFAULT_MONTHS = 12

export default {
  components: { RevenueOverview, VueMonthlyPicker },
  props: {
    iniStartDate: {
      default () {
        return this.$moment().startOf('month').subtract(DEFAULT_MONTHS, 'M').format('YYYY-MM-DD')
      }
    }
  },
  data () {
    return {
      timeFrameOptions: [
        { months: 1, label: '1 Month' },
        { months: 3, label: '3 Months' },
        { months: 6, label: '6 Months' },
        { months: 12, label: '12 Months' },
      ],
      // maxMonths: 12,
      monthSlider: 1,
      months: DEFAULT_MONTHS,
      src: 'reports',
      startDate: this.iniStartDate,
      currentSubject: 'Revenue'
    }
  },
  methods: {
    updateMonths () {
      this.months = parseInt(this.monthSlider)
    },
    decreaseStartDate () {
      this.startDate = this.$moment(this.startDate).subtract(this.months, 'M').format()
    },
    resetStartDate () {
      this.startDate = this.iniStartDate
    },
    increaseStartDate () {
      this.startDate = this.$moment(this.startDate).add(this.months, 'M').format()
    }
  }
}
</script>

<style lang="scss">
th, .source-label {
  white-space: nowrap;
  font-weight: bold;
}
.divider {
  font-weight: bold;
  font-size: 1.2rem;
  color: royalblue;
}
</style>
